import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../components/SEO/seo';
import VideoFullscreen from '../components/VideoFullscreen/videoFullscreen';

const PolytechPage = () => {    
    return (
    <Layout >
       <SEO lang="en" title={'Design for Polytech Museum'} keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'polytech']} description="UX/UI Design for Polytechnic museum of Moscow">
        </SEO>

        <HeroSection animationType="amazingliquid" heading="How to quickly make edits in design and not die?" desc="Design for Polytechnic museum of Moscow."/>

        <Section heading="Project">
            <Paragraph>The Polytechnic Museum of Moscow is changing - it is replenished with new exhibits and design, too, does not stand still.</Paragraph>
            <Paragraph>I was lucky to work with wonderful people on a wonderful project - the Polytech website.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="polytech_project.png" alt="Ready-to-develop designs for Polytech"/>

        <PhotoLeftTextRight salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Prototype" fileURL="polytech_prototypes.png" alt="Prototypes for Polytech">
            <Paragraph>The first thing we started with were prototypes. They help to quickly change the design without getting into beauty and aesthetics. These are the prototypes and turned out. But we will use them to create the final design that will be viewed by millions of people, including people with disabilities.</Paragraph>
            <Paragraph>The supertask was to make an interesting design, looking around the brutalist style, but at the same time convenient for people with disabilities.</Paragraph>
        </PhotoLeftTextRight>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Component Library" >
            <Paragraph>Since we are experienced designers, we know the beauty of a good system design - consistency and flexibility. And we needed her like no one else.</Paragraph>
            <Paragraph>The problem was that the brand book for which we are going to make layouts is late, which means that we need to prepare for changes.</Paragraph>
            <Paragraph>And we just need a flexible component library. Auto-layout helped a lot - figma's ability to automatically expand or shrink components. So when new fonts or elements arrive, the layouts will simply slide apart and you don't have to change every component.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="polytech_designsystem.png" alt="Component library for Polytech's website"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Designs">
            <Paragraph>When we created layouts, we made them immediately in auto-layout so that edits could be made as quickly as possible.</Paragraph>
        </Section>

        <VideoFullscreen salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" youtubeVideoID="pwah8Lmc664"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="In result">
            <Paragraph>It was a very rewarding experience, a lot of knowledge, a lot of experience. It was also interesting to try yourself in brutalism.</Paragraph>
            <Paragraph>In the process, we constantly shared knowledge, and it was not without controversy - there is nothing more controversial than forms and inputs. But, everyone stayed  their own)</Paragraph>
        </Section>

        <PerformanceSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" performances={[{ id: 1, metric: '3 mos', label: 'Of Work' }, { id: 1, metric: '50+', label: 'Screens' }, { id: 1, metric: '1', label: 'Design system' }]}/>

    </Layout>
)};

export default PolytechPage;
